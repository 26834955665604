<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <course-setting-general
        v-if="courseData"
          :courseId="courseData.data.id"
           :field-information="'general'"
        :general-data="courseData"
      />
    </b-tab>
       <b-tab>

 
      <template #title>
        <feather-icon
          icon="RssIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Survey Accessibility  </span>
      </template>
 
      <survey-info-accessibility
        v-if="courseData"
          :courseId="courseData.data.id"
         :field-information="'surveyaccessibility'"
        :information-data="courseData"
      />
    </b-tab> 
      
    <b-tab>

     
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Survey Preferences</span>
      </template>
<course-info-course-preference
        v-if="courseData"
        :courseId="courseData.data.id"
        :field-information="'surveyPreferences'"
        :notification-data="courseData"
      />
    </b-tab>
   

 

    

       
        
        
     
  
    
     
    
     <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Survey Welcome Message</span>
      </template>

      <survey-welcome-info
        v-if="courseData"
           title="Survey Welcome Message"
              :id="courseData.data.id"
              :image="courseData.data.surveyWelcomeImage"
        :field-information="'surveyWelcomeText'"
        :information-data="courseData.data.surveyWelcomeText"
      />
    </b-tab>
      <b-tab>

 
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Thank you Message</span>
      </template>

      <survey-thankyou-info
        v-if="courseData"
          title="Thank you Message"
             :id="courseData.data.id"
             :image="courseData.data.surveyThankyouImage"
      :field-information="'surveyThankyouText'"
         :information-data="courseData.data.surveyThankyouText"
      />
    </b-tab>
       
   
   
    
    
      <!-- 
    

    -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import courseStoreModule from '../surveyStoreModule'
import CourseSettingGeneral from './SurveyInfoGeneral.vue' 
import CourseSettingInformation from './SurveyInfoInformation.vue'  
import CourseInfoContentInfo from './SurveyInfoContentInfo.vue'
import CourseInfoCoursePreference from './SurveyInfoSurveyPreference.vue' 
import SurveyInfoAccessibility from './SurveyInfoAccessibility.vue' 
import LeadershipSurveySettings from './LeaderShipSurveySettings.vue' 
import LeadershipSurveySubject from './LeaderShipSurveySubject.vue'
import SurveyThankyouInfo from './SurveyThankyouInfo.vue'
import SurveyWelcomeInfo from './SurveyWelcomeInfo.vue'


export default {
  components: {
    BTabs,
    BTab,
    CourseSettingGeneral,
   
    CourseSettingInformation,
  
    CourseInfoCoursePreference, 
    CourseInfoContentInfo,
    SurveyInfoAccessibility,
    LeadershipSurveySettings,
    LeadershipSurveySubject,
    SurveyThankyouInfo,
    SurveyWelcomeInfo
  
  },
 
   setup() {
    const courseData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-course-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-course-edit/fetchCourse', { id: router.currentRoute.query.s })
      .then(response => {
   //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        courseData.value =  response.data;
        //console.log("courses assigned to course data");
        // //console.log(response.data);
        
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          courseData.value = undefined
        }
      })

    return {
      courseData,
    }
  },
  data() {
    return {
      courseInfo: JSON.parse(JSON.stringify(this.courseData)), 
      }
      },
 
  beforeCreate() {
     
  // this.courseInfo=this.courseData;
 

 


  return;
   },
}
</script>
